const auger = require('object-auger')

/**
 * Sort an array of objects by a property on the objects
 * @param {Array<Object>} arr - Array of objects to sort
 * @param {string|Array<string>} property - Property or property path to sort by
 * @param {ASC|DESC} direction - Direction to sort
 */
const sortBy = (arr, property, direction = 'ASC') => {
  const propertyPath = Array.isArray(property) ? property : [property]

  if (Array.isArray(arr)) {
    arr.sort((a, b) => {
      const aVal = auger.get(a, propertyPath)
      const bVal = auger.get(b, propertyPath)
      if (aVal > bVal) return direction === 'DESC' ? -1 : 1
      if (aVal < bVal) return direction === 'DESC' ? 1 : -1
      return 0
    })
  }

  return arr
}

module.exports = sortBy
