import React from 'react'
import * as PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const PromotionBanner = props => {
  const { label, link, linkText, outboundLink, text } = props
  return (
    <PromotionBannerContainer>
      <strong>{label}</strong>
      <span>{text}</span>
      {outboundLink ? <OutboundLink href={outboundLink}>{linkText}</OutboundLink> : null}
      {link ? <Link to={link}>{linkText}</Link> : null}
    </PromotionBannerContainer>
  )
}

PromotionBanner.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string,
  outboundLink: PropTypes.string,
  linkText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

PromotionBanner.defaultProps = {
  label: 'Offer',
  linkText: 'See details ›',
}

const PromotionBannerContainer = styled.div`
  align-items: flex-start;
  background-color: ${props => props.theme.color.n20};
  color: ${props => props.theme.color.n700};
  display: flex;
  flex-direction: column;
  font-size: 1.15em;
  justify-content: flex-start;
  margin: 0 0 8px;
  padding: 12px;

  strong {
    background-color: ${props => props.theme.color.g400};
    color: #fff;
    display: inline-block;
    margin-right: 8px;
    padding: 4px 6px;
    text-transform: uppercase;
  }

  span {
    padding: 12px 0;
  }

  a {
    color: ${props => props.theme.color.g400};
    display: inline-block;
    text-decoration: underline;
    white-space: nowrap;

    :hover,
    :focus {
      color: ${props => props.theme.color.n700};
      text-decoration: none;
    }
  }

  :not(:first-of-type) {
    margin-top: 8px;
  }

  @media (min-width: 600px) {
    align-items: center;
    flex-direction: row;
    font-size: 1em;
    justify-content: space-between;
    padding: 8px 12px 8px 8px;

    span {
      flex-grow: 2;
      padding: 0 8px 0 0;
    }
  }
`

export default PromotionBanner
