import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'
import { IoMdGlobe } from '@react-icons/all-files/io/IoMdGlobe'
import UnstyledButton from 'components/button/unstyled-button'
import Content from 'components/content-full-width'

const PreferredLocationRibbon = ({ children, location, toggleModal, ...props }) => {
  return (
    <PreferredLocation {...props}>
      <PreferredLocationContent>
        <IoMdGlobe role='presentation' focusable='false' aria-hidden='true' />
        <span className='preferred-location-text'>
          {location ? 'Your preferred location: ' : null}
          <UnstyledButton
            className='location'
            onClick={() => toggleModal(true)}
            title='Edit preferred location'
          >
            {location ? location : 'Select your preferred location'}
          </UnstyledButton>
        </span>
        {children}
      </PreferredLocationContent>
    </PreferredLocation>
  )
}

PreferredLocationRibbon.propTypes = {
  location: PropTypes.string,
  toggleModal: PropTypes.func.isRequired,
}

const PreferredLocation = styled.div`
  background-color: #fff;
  color: ${props => props.theme.color.n400};
  font-weight: 600;
  font-size: 14px;
  line-height: 1.5;
  width: 100%;

  svg {
    color: ${props => props.theme.color.g400};
    flex-shrink: 0;
    font-size: 1.1em;
    margin-right: 8px;
  }

  .preferred-location-text {
    white-space: nowrap;
  }

  .location {
    color: ${props => props.theme.color.g400};
    display: inline-block;
    text-decoration: underline;

    :hover,
    :focus {
      text-decoration: none;
    }
  }

  @media (min-width: 900px) {
    font-size: 16px;
  }
`

const PreferredLocationContent = styled(Content)`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 16px;
  padding-bottom: 16px;

  @media (min-width: 900px) {
    flex-wrap: nowrap;
  }
`

export default PreferredLocationRibbon
