import React, { useState, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import Layout from 'components/layout'
import Content from 'components/content-full-width'
import { Helmet } from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import { column } from 'styles/column'
import { useToggle } from 'hooks/use-toggle'
import formatPrice from 'utils/format-price'
import sortBy from 'utils/sort-by'
import noop from 'utils/noop'
import pmt from 'utils/pmt'
import QuoteForm from 'components/forms/quote'
import Modal from 'components/modal/modal'
import Button from 'components/button/button'
import Disclaimer from 'components/landing-page/disclaimer'
import Spacer from 'components/new/spacer'
import PreferredLocationRibbon from 'components/preferred-location-ribbon'
import { usePreferredLocation } from 'hooks/use-preferred-location'
import { LocationPickerModal } from 'components/location-picker-modal'
import UnstyledButton from 'components/button/unstyled-button'
import { FiChevronDown } from '@react-icons/all-files/fi/FiChevronDown'
import isDefined from 'utils/is-defined'
import PromotionBanner from '~/components/new/promotion-banner'
import { OutboundLink } from 'gatsby-plugin-google-gtag'

const FINANCING = {
  rate: 0,
  term: 84,
}

const STATUS = {
  AVAILABLE: 'AVAILABLE',
  SOLD: 'SOLD',
  UNAVAILABLE: 'UNAVAILABLE',
}

// Report: https://hutson.lightning.force.com/lightning/r/Report/00OPV0000022py92AA/view

const baseEquipmentData = [
  {
    group: 'compact-tractors',
    id: '1025r-loader',
    imageKey: 'image1025RLoader',
    sku: '1025rlv',
    title: '2024 John Deere 1025R',
    subtitle: 'with loader',
    modelName: '1025R',
    listPrice: 23894,
    price: 18999,
    financing: FINANCING,
    inventory: [
      { locationNumber: 1, units: [{ stockNumber: 360157, status: STATUS.AVAILABLE }] },
      { locationNumber: 5, units: [{ stockNumber: 330096, status: STATUS.AVAILABLE }] },
      {
        locationNumber: 51,
        units: [
          { stockNumber: 348061, status: STATUS.SOLD },
          { stockNumber: 348079, status: STATUS.AVAILABLE },
        ],
      },
      { locationNumber: 53, units: [{ stockNumber: 351685, status: STATUS.AVAILABLE }] },
      {
        locationNumber: 54,
        units: [
          { stockNumber: 348074, status: STATUS.AVAILABLE },
          { stockNumber: 348075, status: STATUS.AVAILABLE },
        ],
      },
      {
        locationNumber: 57,
        units: [
          { stockNumber: 348057, status: STATUS.AVAILABLE },
          { stockNumber: 348082, status: STATUS.AVAILABLE },
          { stockNumber: 348083, status: STATUS.AVAILABLE },
        ],
      },
      {
        locationNumber: 60,
        units: [
          { stockNumber: 348046, status: STATUS.AVAILABLE },
          { stockNumber: 348060, status: STATUS.AVAILABLE },
          { stockNumber: 348088, status: STATUS.AVAILABLE },
          { stockNumber: 348089, status: STATUS.AVAILABLE },
        ],
      },
      {
        locationNumber: 62,
        units: [
          { stockNumber: 348090, status: STATUS.AVAILABLE },
          { stockNumber: 348091, status: STATUS.AVAILABLE },
        ],
      },
      { locationNumber: 65, units: [{ stockNumber: 348067, status: STATUS.AVAILABLE }] },
      {
        locationNumber: 66,
        units: [{ stockNumber: 348070, status: STATUS.AVAILABLE }],
      },
      {
        locationNumber: 68,
        units: [
          { stockNumber: 348064, status: STATUS.AVAILABLE },
          { stockNumber: 348065, status: STATUS.AVAILABLE },
        ],
      },
      { locationNumber: 72, units: [{ stockNumber: 348077, status: STATUS.SOLD }] },
      { locationNumber: 73, units: [{ stockNumber: 348069, status: STATUS.AVAILABLE }] },
    ],
  },
  {
    group: 'compact-tractors',
    id: '1025r-loader-deck',
    imageKey: 'image1025RLoaderDeck',
    sku: '1025rlv',
    title: '2024 John Deere 1025R',
    subtitle: 'with loader & deck',
    modelName: '1025R',
    listPrice: 27751,
    price: 21999,
    financing: FINANCING,
    inventory: [
      { locationNumber: 10, units: [{ stockNumber: 360149, status: STATUS.AVAILABLE }] },
      {
        locationNumber: 62,
        units: [{ stockNumber: 329404, status: STATUS.SOLD }],
      },
      {
        locationNumber: 66,
        units: [{ stockNumber: 348039, status: STATUS.AVAILABLE }],
      },
    ],
  },
  {
    group: 'compact-tractors',
    id: '2025r-loader',
    imageKey: 'image2038RLoader',
    sku: '2025rlv',
    title: '2024 John Deere 2025R',
    subtitle: 'with loader',
    modelName: '2025R',
    listPrice: 27057,
    price: 21499,
    financing: FINANCING,
    inventory: [
      { locationNumber: 51, units: [{ stockNumber: 333448, status: STATUS.AVAILABLE }] },
      { locationNumber: 60, units: [{ stockNumber: 333447, status: STATUS.AVAILABLE }] },
      { locationNumber: 61, units: [{ stockNumber: 327264, status: STATUS.AVAILABLE }] },
      { locationNumber: 71, units: [{ stockNumber: 348130, status: STATUS.AVAILABLE }] },
    ],
  },
  // {
  //   group: 'compact-tractors',
  //   id: '2038r-loader',
  //   imageKey: 'image2038RLoader',
  //   sku: '2038rlv',
  //   title: '2023 John Deere 2038R',
  //   subtitle: 'with loader',
  //   modelName: '2038R',
  //   listPrice: 36695,
  //   price: 31499,
  //   financing: FINANCING,
  //   inventory: [
  //     {
  //       locationNumber: 51,
  //       units: [{ stockNumber: 321865, status: STATUS.AVAILABLE }],
  //     },
  //     {
  //       locationNumber: 61,
  //       units: [{ stockNumber: 321857, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 66,
  //       units: [{ stockNumber: 321858, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 69,
  //       units: [{ stockNumber: 361423, status: STATUS.AVAILABLE }],
  //     },
  //     {
  //       locationNumber: 70,
  //       units: [{ stockNumber: 333224, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 71,
  //       units: [{ stockNumber: 361503, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 72,
  //       units: [{ stockNumber: 361504, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 73,
  //       units: [{ stockNumber: 321877, status: STATUS.AVAILABLE }],
  //     },
  //   ],
  // },
  // {
  //   group: 'compact-tractors',
  //   id: '3025e-loader',
  //   imageKey: null,
  //   sku: '3025elv',
  //   title: '2023 John Deere 3025E',
  //   subtitle: 'with loader',
  //   modelName: '3025E',
  //   listPrice: 28811,
  //   price: 23999,
  //   financing: FINANCING,
  //   slug: null,
  //   inventory: [
  //     {
  //       locationNumber: 5,
  //       units: [
  //         { stockNumber: 339059, status: STATUS.AVAILABLE },
  //         { stockNumber: 346631, status: STATUS.AVAILABLE },
  //       ],
  //     },
  //     {
  //       locationNumber: 6,
  //       units: [{ stockNumber: 341057, status: STATUS.AVAILABLE }],
  //     },
  //     {
  //       locationNumber: 8,
  //       units: [{ stockNumber: 339065, status: STATUS.AVAILABLE }],
  //     },
  //     {
  //       locationNumber: 14,
  //       units: [{ stockNumber: 346632, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 53,
  //       units: [{ stockNumber: 344559, status: STATUS.AVAILABLE }],
  //     },
  //   ],
  // },
  {
    group: 'compact-tractors',
    id: '3033r-cab-loader',
    imageKey: 'image3039RCabLoader',
    sku: '3033rlv',
    title: '2024 John Deere 3033R',
    subtitle: 'with cab & loader',
    modelName: '3033R',
    listPrice: 52088,
    price: 42000,
    financing: FINANCING,
    slug: null,
    inventory: [
      {
        locationNumber: 53,
        units: [{ stockNumber: 363400, status: STATUS.AVAILABLE }],
      },
    ],
  },
  {
    group: 'compact-tractors',
    id: '3039r-cab-loader',
    imageKey: 'image3039RCabLoader',
    sku: '3039rlv',
    title: '2023 John Deere 3039R',
    subtitle: 'with standard cab & loader',
    modelName: '3039R',
    listPrice: 48999,
    price: 39999,
    financing: FINANCING,
    slug: null,
    inventory: [
      {
        locationNumber: 62,
        units: [{ stockNumber: 360768, status: STATUS.AVAILABLE }],
      },
    ],
  },
  {
    group: 'compact-tractors',
    id: '3046r-loader',
    imageKey: null,
    sku: '3046rlv',
    title: '2023 John Deere 3046R',
    subtitle: 'with loader',
    modelName: '3046R',
    listPrice: 44380,
    price: 34999,
    financing: FINANCING,
    slug: null,
    inventory: [
      {
        locationNumber: 69,
        units: [{ stockNumber: 333397, status: STATUS.AVAILABLE }],
      },
    ],
  },
  // {
  //   group: 'compact-tractors',
  //   id: '4052m-prt',
  //   imageKey: 'image4044MLoader',
  //   sku: '4052mlv',
  //   title: '2023 John Deere 4052M',
  //   subtitle: 'with eHydro transmission',
  //   modelName: '4052M',
  //   listPrice: 48234,
  //   price: 39999,
  //   financing: FINANCING,
  //   slug: null,
  //   inventory: [
  //     {
  //       locationNumber: 51,
  //       units: [
  //         { stockNumber: 327935, status: STATUS.SOLD },
  //         { stockNumber: 343626, status: STATUS.SOLD },
  //       ],
  //     },
  //     {
  //       locationNumber: 61,
  //       units: [{ stockNumber: 333492, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 62,
  //       units: [{ stockNumber: 344610, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 66,
  //       units: [
  //         { stockNumber: 333489, status: STATUS.SOLD },
  //         { stockNumber: 333490, status: STATUS.SOLD },
  //       ],
  //     },
  //     {
  //       locationNumber: 68,
  //       units: [{ stockNumber: 341136, status: STATUS.SOLD }],
  //     },
  //   ],
  // },
  {
    group: 'compact-tractors',
    id: '4066m',
    imageKey: 'image4044MLoader',
    sku: '4066mlv',
    title: '2023 John Deere 4066M',
    subtitle: 'with eHydro transmission & loader',
    modelName: '4066M',
    listPrice: 51025,
    price: 39999,
    financing: FINANCING,
    slug: null,
    inventory: [
      {
        locationNumber: 58,
        units: [{ stockNumber: 326170, status: STATUS.SOLD }],
      },
      {
        locationNumber: 62,
        units: [
          { stockNumber: 333487, status: STATUS.AVAILABLE },
          { stockNumber: 333488, status: STATUS.AVAILABLE },
        ],
      },
      {
        locationNumber: 68,
        units: [{ stockNumber: 326171, status: STATUS.SOLD }],
      },
      {
        locationNumber: 69,
        units: [{ stockNumber: 351937, status: STATUS.AVAILABLE }],
      },
      {
        locationNumber: 70,
        units: [{ stockNumber: 319013, status: STATUS.AVAILABLE }],
      },
      {
        locationNumber: 71,
        units: [{ stockNumber: 351936, status: STATUS.AVAILABLE }],
      },
    ],
  },
  // {
  //   group: 'compact-tractors',
  //   id: '4066m-hd',
  //   imageKey: 'image4066MHDLoader',
  //   sku: '4066hlv',
  //   title: '2023 John Deere 4066M Heavy Duty',
  //   subtitle: 'with eHydro transmission',
  //   modelName: '4066M HD',
  //   listPrice: 63417,
  //   price: 52999,
  //   financing: FINANCING,
  //   slug: null,
  //   inventory: [
  //     {
  //       locationNumber: 60,
  //       units: [{ stockNumber: 321966, status: STATUS.SOLD }],
  //     },
  //     {
  //       locationNumber: 68,
  //       units: [{ stockNumber: 321965, status: STATUS.SOLD }],
  //     },
  //   ],
  // },
]

const EndOfSeasonSalesEventLandingPage = props => {
  const {
    data: { allDeereProduct, allSanityJohnDeereEquipment, allSanityLocation, heroImage },
    data,
    location,
  } = props

  const { preferredLocation } = usePreferredLocation()

  const preferredLocationRegion = preferredLocation
    ? Number(preferredLocation.locationNumber) > 20
      ? 'michigan'
      : 'south'
    : null

  const [locationPickerModalOpen, toggleLocationPickerModal] = useToggle(false)

  const [equipmentData] = useState(() => {
    return baseEquipmentData.map(obj => {
      const productMatch = allDeereProduct.nodes.find(node => node.sku === obj.sku)
      const johnDeereEquipmentMatch = allSanityJohnDeereEquipment.nodes.find(
        node => node.sku === obj.sku
      )

      const categoryCodes = johnDeereEquipmentMatch
        ? [
            {
              name: 'Category Code',
              value: johnDeereEquipmentMatch.categoryCode,
            },
            {
              name: 'Subcategory Code',
              value: johnDeereEquipmentMatch.subcategoryCode,
            },
          ]
        : null

      const inventory = obj.inventory.map(({ locationNumber, units }) => {
        const unitsAvailable = units.filter(unit => unit.status === STATUS.AVAILABLE).length

        const location = allSanityLocation.nodes.find(
          node => node.locationNumber === locationNumber
        )

        return {
          locationNumber,
          location,
          region: Number(locationNumber) > 20 ? 'michigan' : 'south',
          units: sortBy(units, 'stockNumber'),
          unitsAvailable,
        }
      })

      const slug = obj.slug || productMatch ? productMatch.slug : null

      const image =
        obj.imageKey && data[obj.imageKey]
          ? data[obj.imageKey].childImageSharp.gatsbyImageData
          : johnDeereEquipmentMatch
          ? johnDeereEquipmentMatch.firstImage.asset.gatsbyImageData
          : null

      return {
        ...productMatch,
        ...obj,
        categoryCodes,
        slug,
        image,
        inventory: sortBy(inventory, ['location', 'title']),
        noneAvailable: inventory.filter(obj => obj.unitsAvailable !== 0).length === 0,
      }
    })
  })

  const [open, toggleModal] = useToggle(false)
  const [selectedProduct, setSelectedProduct] = useState()

  useEffect(() => {
    if (window && window.dataLayer && selectedProduct) {
      window.dataLayer.push({
        'product-price': selectedProduct.price || 0,
      })
    }
  }, [selectedProduct])

  const openQuoteForm = e => {
    const target = e.target
    const id = target.getAttribute('data-id')
    const match = equipmentData.find(obj => obj.id === id)
    if (match) {
      setSelectedProduct(match)
      toggleModal(true)
    }
  }
  return (
    <Layout>
      <Helmet>
        <title>End of Season Sales Event | Hutson Inc</title>
        <meta name='robots' content='noindex' />
      </Helmet>
      <Hero>
        <HeroImageContainer>
          <HeroText>
            <Title>End of Season Sales Event</Title>
            <Description>
              Special clearance pricing on select John Deere compact utility tractors & Gators
            </Description>
          </HeroText>
          <HeroOverlay />
          <HeroImage
            image={heroImage.childImageSharp.gatsbyImageData}
            alt=''
            objectFit='cover'
            objectPosition='75% 50%'
          />
        </HeroImageContainer>
      </Hero>
      <PreferredLocationRibbon
        location={preferredLocation?.title}
        toggleModal={toggleLocationPickerModal}
      >
        <PreferredLocationDetails>
          {preferredLocationRegion
            ? `Showing available units at our ${
                preferredLocationRegion === 'south'
                  ? 'Kentucky, Tennessee, and Indiana'
                  : 'Michigan'
              } locations`
            : 'Select a location to see available units near you'}
        </PreferredLocationDetails>
      </PreferredLocationRibbon>
      <LocationPickerModal
        modalProps={{ isOpen: locationPickerModalOpen, toggle: toggleLocationPickerModal }}
      />
      <LightBackground>
        <Content>
          <PromotionBanner
            text='0% for 84 months financing available on all in stock <100 HP John Deere compact utility tractors'
            outboundLink='https://creditapp.financial.deere.com/?dealer=034321&type=CUTT&country=us'
            linkText='Apply online'
          />
          <Spacer size='l' />
          <Grid>
            {equipmentData.map(obj => (
              <GridItem
                key={obj.id}
                openQuoteForm={openQuoteForm}
                preferredLocationRegion={preferredLocationRegion}
                {...obj}
              />
            ))}
          </Grid>
          <Spacer />
          <Disclaimer>
            Offers valid October 2 through October 25, 2024 while supplies last. Prices and
            availability may vary by dealer. Taxes not included. Financing subject to approved
            credit by John Deere Financial. Down payment may be required to receive financing.
            Images for illustration purposes only. Residency restrictions, terms and conditions may
            apply See dealer for details.
          </Disclaimer>
        </Content>
      </LightBackground>

      <Modal toggle={toggleModal} isOpen={open}>
        <QuoteForm
          toggleModal={toggleModal}
          productName={`End of Season Sales Event 2024 Q4${
            selectedProduct
              ? ` - ${selectedProduct.title}${
                  selectedProduct.subtitle ? ` ${selectedProduct.subtitle}` : ''
                }`
              : ''
          }`}
          productPrice={selectedProduct ? selectedProduct.price || 0 : 0}
          productType='john-deere'
          submitButtonClass='submit-quote'
          pageURL={location.href}
          hiddenValues={selectedProduct?.categoryCodes ? selectedProduct.categoryCodes : []}
          header={selectedProduct && selectedProduct.price ? 'Talk to sales' : 'Get a quote'}
          equipmentBrand='John Deere'
          equipmentCondition='new'
        />
      </Modal>
    </Layout>
  )
}

const LightBackground = styled.div`
  background-color: ${props => props.theme.color.n10};
`

const Hero = styled.div`
  height: 300px;
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    height: 500px;
  }
`

const HeroImageContainer = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
`

const HeroOverlay = styled.div`
  background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 100%);
  height: 100%;
  position: absolute;
  width: 100%;
  z-index: 1;

  @media (min-width: 600px) {
    background: linear-gradient(to top, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.1) 100%);
  }
`

const HeroText = styled(Content)`
  color: #fff;
  left: 50%;
  padding-bottom: 0;
  position: absolute;
  bottom: 10%;
  transform: translateX(-50%);
  z-index: 2;
`

const HeroImage = styled(GatsbyImage)`
  min-height: 100%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 0;
`

const Title = styled.h1`
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.25;
  margin: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 48px;
  }
`

const Description = styled.p`
  color: #efefef;
  font-size: 21px;
  font-style: italic;
  font-weight: 400;
  line-height: 1.25;
  margin: 0;
  text-align: left;

  @media (min-width: 600px) {
    font-size: 24px;
  }
`

const PreferredLocationDetails = styled.span`
  border-top: 1px solid ${props => props.theme.color.n40};
  display: inline-block;
  font-weight: 400;
  margin-top: 8px;
  padding-top: 8px;
  width: 100%;

  @media (min-width: 900px) {
    border-left: 1px solid ${props => props.theme.color.n40};
    border-top: none;
    margin-left: 16px;
    margin-top: 0;
    padding-left: 16px;
    padding-top: 0;
    width: auto;
  }
`

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const GridItem = ({
  financing,
  id,
  image,
  inventory,
  listPrice,
  modelName,
  noneAvailable,
  openQuoteForm,
  price,
  preferredLocationRegion,
  sku,
  slug,
  subtitle,
  title,
}) => {
  const [inventoryDropdownOpen, toggleInventoryDropdown] = useToggle(false)
  const [state, setState] = useState({})

  const { noneAvailableInRegion, showingInventory, totalLocationsCount, totalUnitsCount } = state

  useEffect(() => {
    const showingInventory = inventory.filter(obj => {
      return (
        (preferredLocationRegion ? obj.region === preferredLocationRegion : true) &&
        obj.unitsAvailable > 0
      )
    })

    const totalLocationsCount = showingInventory.length

    const totalUnitsCount = showingInventory.reduce((total, { unitsAvailable }) => {
      return total + unitsAvailable
    }, 0)

    const noneAvailableInRegion = noneAvailable || totalLocationsCount === 0

    setState({
      noneAvailableInRegion,
      showingInventory,
      totalLocationsCount,
      totalUnitsCount,
    })
  }, [inventory, preferredLocationRegion, noneAvailable])

  return (
    <GridItemContainer className={noneAvailableInRegion ? 'sold-out' : ''}>
      {listPrice ? (
        <GridItemOfferLabels>
          <span>
            {(listPrice - price).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
            })}{' '}
            off list
          </span>
        </GridItemOfferLabels>
      ) : null}
      {image ? (
        <GridItemImage image={image} alt={title} objectFit='contain' className='grid-item-image' />
      ) : null}
      <GridItemTextContainer>
        <div>
          <GridItemTitle>{title}</GridItemTitle>
          {subtitle ? <GridItemText>{subtitle}</GridItemText> : null}
        </div>
        {noneAvailableInRegion !== undefined ? (
          <GridItemStockDetails>
            <GridItemStockDetailsInventory className={inventoryDropdownOpen ? 'active' : ''}>
              <UnstyledButton onClick={toggleInventoryDropdown}>
                {noneAvailableInRegion ? (
                  <span>No units available</span>
                ) : (
                  <span>
                    {totalUnitsCount} {totalUnitsCount > 1 ? 'units' : 'unit'} at{' '}
                    {totalLocationsCount} {totalLocationsCount > 1 ? 'locations' : 'location'}
                  </span>
                )}
                {noneAvailableInRegion ? null : (
                  <FiChevronDown role='presentation' aria-hidden='true' focusable='false' />
                )}
              </UnstyledButton>
              <div className='stock-details-inventory-dropdown'>
                {(showingInventory ? showingInventory : inventory).map(obj => (
                  <div key={obj.locationNumber} className='stock-details-location'>
                    <span className='stock-details-location-title'>
                      <Link to={obj.location.slug}>{obj.location.title}</Link>
                    </span>
                    <div className='stock-details-units'>
                      {obj.units.map(unit => (
                        <GridItemStockDetailsTag key={unit.stockNumber}>
                          #{unit.stockNumber}
                          {unit.hours ? ` with ${unit.hours} hours` : null}
                          {unit.status === STATUS.UNAVAILABLE && unit.availableDate
                            ? ` (available ${unit.availableDate})`
                            : null}
                          {unit.status === STATUS.SOLD ? ' (sold)' : null}
                        </GridItemStockDetailsTag>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </GridItemStockDetailsInventory>
          </GridItemStockDetails>
        ) : null}
        <GridItemPricingContainer>
          <GridItemPrice>{formatPrice(price, { round: true })}</GridItemPrice>
          {listPrice ? (
            <GridItemWasPrice>{formatPrice(listPrice, { round: true })} List</GridItemWasPrice>
          ) : null}
          {financing ? (
            isDefined(financing.rate) && isDefined(financing.term) ? (
              <React.Fragment>
                <br />
                or{' '}
                <GridItemPrice>
                  {formatPrice(pmt(financing.rate, financing.term, price), {
                    round: true,
                  })}
                </GridItemPrice>
                /month
                <br />
                at {financing.rate === 0 ? 0 : (financing.rate * 100).toFixed(2)}% for{' '}
                {financing.term} months with no money down
              </React.Fragment>
            ) : financing.title ? (
              <React.Fragment>
                <br />
                {financing.title}
              </React.Fragment>
            ) : null
          ) : null}
        </GridItemPricingContainer>
        {noneAvailableInRegion !== undefined ? (
          <div>
            {noneAvailableInRegion ? (
              <GridItemButton onClick={noop} color='gray'>
                Sold out
              </GridItemButton>
            ) : (
              <React.Fragment>
                <GridItemButton data-id={id} onClick={openQuoteForm} color='yellow'>
                  Talk to sales
                </GridItemButton>
                <Button
                  color='green'
                  ghost
                  as={SecondaryButtonA}
                  href={`https://creditapp.financial.deere.com/?dealer=034321&ref=${sku}&type=CUTT&country=us`}
                >
                  Apply for financing
                </Button>
              </React.Fragment>
            )}
          </div>
        ) : null}
        {slug ? (
          <div>
            <Spacer size='xs' />
            <GridItemLearnMoreLink to={slug}>
              <span>Learn more about the {modelName || title}</span>
            </GridItemLearnMoreLink>
          </div>
        ) : null}
      </GridItemTextContainer>
    </GridItemContainer>
  )
}

const GridItemContainer = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 24px;
  position: relative;
  width: 100%;

  &.sold-out {
    .stock-details-inventory-dropdown {
      display: none !important;
    }
  }

  hr {
    background-color: ${props => props.theme.color.n40};
    border: none;
    height: 1px;
    margin: 16px 0;
    width: 100%;
  }

  sup {
    font-size: 0.65em;
    top: -0.5em;
  }

  @media (min-width: 600px) {
    ${column('1/2')}
    float: none;
    clear: none;
  }

  @media (min-width: 900px) {
    ${column('1/3')}
  }

  /* @media (min-width: 1200px) {
    ${column('1/4')}
  } */
`

const GridItemOfferLabels = styled.div`
  margin-bottom: 16px;
  position: absolute;
  left: 24px;
  top: 24px;
  z-index: 1;

  span {
    background-color: #fee2e2;
    color: #ef4444;
    font-size: 13px;
    font-weight: 700;
    margin: 0 4px 4px 0;
    padding: 4px 6px;
  }
`

const GridItemImage = styled(GatsbyImage)`
  max-height: 150px;
`

const GridItemTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
  margin-top: 24px;
`

const GridItemTitle = styled.h3`
  display: inline-block;
  font-size: 27px;
  font-weight: 600;
  margin: 0 8px 0 0;
  max-width: none;
  padding: 0;
  text-transform: none;
  width: 100%;
`

const GridItemText = styled.span`
  display: inline-block;
  font-size: 18px;
  font-weight: 400;
  margin-right: 5px;
`

const GridItemStockDetails = styled.div`
  color: ${props => props.theme.color.n400};
  display: block;
  font-size: 13px;
  margin: 8px 0 0;
`

const GridItemStockDetailsTag = styled.div`
  background-color: ${props => props.theme.color.n20};
  display: inline-block;
  margin: 0 6px 6px 0;
  padding: 4px 6px;

  a {
    color: ${props => props.theme.color.g400};
    display: inline-block;
    text-decoration: underline;
  }
`

const GridItemStockDetailsInventory = styled.div`
  background-color: ${props => props.theme.color.n20};
  color: ${props => props.theme.color.n400};
  display: block;
  margin: 4px 0;

  &.active {
    .stock-details-inventory-dropdown {
      display: block;
    }

    button {
      svg {
        transform: rotate(180deg);
      }
    }
  }

  button {
    display: flex;
    justify-content: space-between;
    padding: 8px;
    width: 100%;

    svg {
      color: ${props => props.theme.color.n400};
    }
  }

  .stock-details-inventory-dropdown {
    display: none;
    padding: 4px 8px 8px;

    ${GridItemStockDetailsTag} {
      background-color: ${props => props.theme.color.n30};
    }

    .stock-details-location {
      align-items: flex-start;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;

      :not(:first-child) {
        padding-top: 6px;
        border-top: 1px solid ${props => props.theme.color.n30};
      }
    }

    .stock-details-location-title {
      display: inline-block;
      flex-shrink: 0;
      font-weight: 600;
      padding: 4px 6px 0 0;
    }

    .stock-details-units {
      text-align: right;
    }
  }

  a {
    color: ${props => props.theme.color.g400};
    display: inline-block;
    text-decoration: underline;
  }
`

const GridItemPricingContainer = styled.div`
  flex-grow: 2;
  line-height: 1.35;
  margin-top: 8px;
`

const GridItemPrice = styled.span`
  display: inline-block;
  font-size: 1.75em;
  font-weight: 700;
  margin-right: ${props => (props.nomargin ? 0 : '5px')};
`

const GridItemWasPrice = styled.span`
  color: #7d7d7d;
  display: inline-block;
  font-size: 1.2em;
  font-weight: 400;
`

const GridItemButton = styled(Button)`
  display: inline-block;
  margin: 20px 8px 8px 0;
`

const SecondaryButtonA = styled(OutboundLink)`
  display: inline-block;
  margin: 8px 0 16px;
  text-decoration: none;
`

const GridItemLearnMoreLink = styled(Link)`
  color: #377539;
  display: inline-block;
  text-decoration: none;

  span {
    text-decoration: underline;
  }

  :hover,
  :focus {
    color: #000;
  }

  :after {
    content: ' ›';
    display: inline;
    text-decoration: none;
  }
`

export const pageQuery = graphql`
  query endOfSeasonSalesEventLandingPage(
    $skus: [String]! = ["1025rlv", "2025rlv", "3033rlv", "3039rlv", "3046rlv", "4066mlv"]
  ) {
    allSanityJohnDeereEquipment(filter: { sku: { in: $skus } }) {
      nodes {
        categoryCode
        firstImage {
          asset {
            gatsbyImageData
          }
        }
        sku
        subcategoryCode
      }
    }
    allDeereProduct(filter: { sku: { in: $skus } }) {
      nodes {
        slug
        sku
      }
    }
    heroImage: file(relativePath: { eq: "landing-pages/cuts-inventory-reduction-sale-hero.jpg" }) {
      ...FullWidthImage
    }
    allSanityLocation {
      nodes {
        locationNumber
        slug
        title
      }
    }
    image1025RLoader: file(relativePath: { eq: "landing-pages/assets/1025r-loader.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    image1025RLoaderDeck: file(relativePath: { eq: "landing-pages/assets/1025r-loader-deck.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    image2038RLoader: file(relativePath: { eq: "landing-pages/assets/2038r-loader.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    image3039RCabLoader: file(relativePath: { eq: "landing-pages/assets/3039r-cab-loader.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    image4044MLoader: file(relativePath: { eq: "landing-pages/assets/4044m-loader.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
  }
`

export default EndOfSeasonSalesEventLandingPage
